import React, { useEffect, useState } from "react";
import HSWInputMarker from "../Helper/HSWInputMarker";
import { columnsHSW } from "../Helper/Columns";
import { useHSWContext } from "../../Context/HealthAndSafetyWarningsProvider";

function DateFormatting(dateStr) {
  const formattedDate = dateStr
    .substr(dateStr.indexOf("-") + 1, dateStr.indexOf("T") - 5)
    .replace("-", "/");

  const hour = parseInt(dateStr.substr(dateStr.indexOf("T") + 1, 2));

  let sign = "";
  if (hour === 7) {
    sign = "d"; // Morning
  } else if (hour === 15) {
    sign = "e"; // Afternoon
  } else if (hour === 23) {
    sign = "n"; // Night
  }

  return `${formattedDate}${sign}`;
}

function getGradientStyle(value, rowClass) {
  if (value === null || value === "-") return {}; // No gradient for null or empty values

  const numericValue = parseFloat(value);
  if (numericValue > 0) {
    let backgroundColor;
    if (rowClass === "nightRow") {
      backgroundColor = "rgb(227, 202, 202)";
    } else if (rowClass === "eveningRow") {
      backgroundColor = "rgb(255, 225, 225)";
    } else {
      backgroundColor = "rgb(255, 239, 239)";
    }
    return {
      color: `rgb(196, 30, 58)`,
      fontWeight: `bold`,
      fontSize: `11px`,
      background: backgroundColor,
    };
  }

  return {};
}

const Reacttable = ({ departmentId, position, resolution }) => {
  let HSWContext = useHSWContext();
  let [result, setResult] = useState([]);
  let [inputId, setInputId] = useState({ clicked: null });

  useEffect(() => {
    if (HSWContext.propData !== undefined && HSWContext.propData !== null) {
      setResult(HSWContext.propData);
    }
  }, [HSWContext.propData]);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  function checkZero(value) {
    if (value === null) {
      return (value = "-");
    }
    return value;
  }

  const mapped = Object.values(result);

  return (
    <div
      className={"point-container " + resolution}
      style={{
        paddingBottom: position,
      }}
    >
      <table className={"pointsTable " + resolution}>
        <thead>
          <tr className="HSWTable">
            {columnsHSW.map((head, index) => (
              <th className="HSWheader" key={index}>
                {head}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {mapped.map((point, index) => {
            const dateFormatted = DateFormatting(point.Date);
            const rowClass =
              dateFormatted.includes("e")
                ? "eveningRow"
                : dateFormatted.includes("n")
                ? "nightRow"
                : "";

            return (
              <tr key={index} className={`HSWrow ${rowClass}`}>
                <td>{DateFormatting(point.Date)}</td>
                <HSWInputMarker
                  clicked={inputId.clicked}
                  index={"lad-" + index}
                  value={checkZero(point.HSW_LAD)}
                  setInputId={setInputId}
                  perfId={point.Id}
                  departmentId={3}
                  dateOfEntry={point.Date}
                  style={getGradientStyle(point.HSW_LAD, rowClass)}
                />
              <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"df-" + index}
                    value={checkZero(point.HSW_DF)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={2}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_DF, rowClass)}
                  />
              <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"std-" + index}
                    value={checkZero(point.HSW_STD)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={8}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_STD, rowClass)}
                  />
              <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"n7-" + index}
                    value={checkZero(point.HSW_N7)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={4}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_N7, rowClass)}
                  />
              <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"lin-" + index}
                    value={checkZero(point.HSW_LIN)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={0}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_LIN, rowClass)}
                  />
              <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"panel-" + index}
                    value={checkZero(point.HSW_PANEL)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={6}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_PANEL, rowClass)}
                  />
              <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"custom-" + index}
                    value={checkZero(point.HSW_CUSTOM)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={10}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_CUSTOM, rowClass)}
                  />
              <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"paintline-" + index}
                    value={checkZero(point.HSW_Paintline)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={9}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_Paintline, rowClass)}
                  />
              <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"louvers-" + index}
                    value={checkZero(point.HSW_Louvers)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={11}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_Louvers, rowClass)}
                  />
              <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"stamping-" + index}
                    value={checkZero(point.HSW_Stamping)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={13}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_Stamping, rowClass)}
                  />
                {DateFormatting(point.Date).includes("d") ? (
                <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"techmical-" + index}
                    value={checkZero(point.HSW_Technical)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={14}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_Technical, rowClass)}
                />
                ) : (
                <td className="emptyHSWCell">_</td>
                )}
                {DateFormatting(point.Date).includes("d") ? (
                <HSWInputMarker
                    clicked={inputId.clicked}
                    index={"management-" + index}
                    value={checkZero(point.HSW_Management)}
                    setInputId={setInputId}
                    perfId={point.Id}
                    departmentId={12}
                    dateOfEntry={point.Date}
                    style={getGradientStyle(point.HSW_Management)} // Pass gradient style
                  />
                ) : (
                <td className="emptyHSWCell">_</td>
                )}
            </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Reacttable;
