import React, { useState } from "react";
import LineGraph from "../../PerformanceDash/Components/Charts/LineChart";
import BarGraph from "../../PerformanceDash/Components/Charts/BarChart";
import Reacttable from "../../PerformanceDash/Components/Tables/HSWTable.js";
import { addDays } from "date-fns";
import DateRangeComp from "../DateRangeComp";
import TrendIcon from "../../assets/images/TrendIcon.svg";
import ArrowsIcon from "../../assets/images/ArrowsIcon.svg";
import CloseArrowsIcon from "../../assets/images/CloseArrowsIcon.svg";
import ChartIcon from "../../assets/images/ChartIcon.svg";
import HistoryIcon from "../../assets/images/HistoryIcon.svg";
import { PerformanceProvider } from "../../PerformanceDash/Context/PerformanceProvider";
import { HealthAndSafetyWarningsProvider } from "../../PerformanceDash/Context/HealthAndSafetyWarningsProvider";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function GetDate(date) {
  return (
    doubleDigit(date.getMonth() + 1) +
    "/" +
    doubleDigit(date.getDate().toString()) +
    "/" +
    date.getFullYear().toString()
  );
}

function doubleDigit(value) {
  if (value.toString().length === 1) {
    let result = `${"0".repeat(1)}${value.toString()}`;
    return result;
  } else {
    return value;
  }
}
function HSWWindow() {
  let [value, setValue] = useState(8);
  const [start, setStart] = useState([
    {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  return (
    <div>
      <div className="hsw__block-top">
        <div className="hsw__right-column">
          <div>
            <DateRangeComp setStart={setStart} />
          </div>
        </div>
      </div>
      <HealthAndSafetyWarningsProvider
        startDate={GetDate(start[0].startDate)}
        endDate={GetDate(start[0].endDate)}
        depId={value}
      >
        <div className="hsw__graphs-block">
          <div className="hsw__graph">
            <div className="graph-title">
              <div>
                <img src={HistoryIcon} alt="history"/>
                <span>Health and Safety Warning History</span>
              </div>
            </div>
            <Reacttable
              departmentId={value}
              resolution={"small"}
            />
          </div>
        </div>
      </HealthAndSafetyWarningsProvider>
    </div>
  );
}

export default HSWWindow;
