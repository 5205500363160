import React, { useEffect, useState } from "react";
import useSWR from "swr";
import axios from "axios";
import { SGD_API_HOST } from "../../env";

interface HSWDataItem {
  Date: string;
  HSW_LAD: number;
  HSW_DF: number;
  HSW_STD: number;
  HSW_N7: number;
  HSW_LIN: number;
  HSW_PANEL: number;
  HSW_CUSTOM: number;
  HSW_Louvers: number;
  HSW_Management: number;
  HSW_Paintline: number;
  HSW_Stumping: number;
  HSW_Technical: number;
  Id: string;
}


const Item: Array<HSWDataItem> = [
  {
    Date: "2023-01-09T06:00:00.001927",
    HSW_LAD: 0,
    HSW_DF: 0,
    HSW_STD: 0,
    HSW_N7: 0,
    HSW_LIN: 0,
    HSW_PANEL: 0,
    HSW_CUSTOM: 0,
    HSW_Louvers: 0,
    HSW_Management: 0,
    HSW_Paintline: 0,
    HSW_Stumping: 0,
    HSW_Technical: 0,
    Id: ""
  },
];

const HSWContext = React.createContext({
    propData: {...Item}
  });

  function HealthAndSafetyWarningsProvider({startDate,endDate,depId, children }){
    let [fetched, setFetched] = useState({...Item});//initialize state
    let [department, setDepartment] = useState(depId);
    let [start, setStart] = useState(startDate);
    let [end, setEnd] = useState(endDate);
    let [isLoading, setLoading] = useState(false)
    useEffect(() => {
        if (department !== depId || start !== startDate || end !== endDate) {
          setDepartment(depId);
          setStart(startDate);
          setEnd(endDate);
          setLoading(false);
        }
    
        if (!isLoading) {    
          setLoading(true);
        }
      }, [isLoading, depId, startDate, endDate,start,end,department]);
    
    const fetcher = url => axios.request({
      url:url,
      method: 'get',
      baseURL:SGD_API_HOST,
      params:{
        startDate:start,
        endDate:end
      },
      responseType:'json',
    }).then(res => res.data)
   

    const { data } = useSWR('/api/HealthAndSafety/get-full-hsw-by-date', fetcher, { refreshInterval: 3000 })//fetch data every 10 seconds

    useEffect(()=>{
      //if data has changed, then update state
      if(data !== undefined){
        setFetched(data)
      }
      
    },[data])
    return (
      <HSWContext.Provider 
        value={{
          propData: fetched 
        }}>
        {children}
      </HSWContext.Provider>
    );
  }
  
  function useHSWContext() {
    const context = React.useContext(HSWContext);
    if (context === undefined) {
      throw new Error("Error...");
    }
    return context;
  }

  export {HealthAndSafetyWarningsProvider, useHSWContext, HSWContext, Item}

