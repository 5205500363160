const small = [
    "Date",
    "Shift",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Last5DaysPerfPercent",
  ];
  const smallLIN = [
    "Date",
    "Shift",
    "Points",
    "Loading",
    "PerfDaily",
    "Last6WeeksPerformance",
    "PerfWeekly",
    "Last5DaysPerfPercent",
  ];
  const columnsDf = [
    "Date",
    "Shift",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Last5DaysPerfPercent",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
    "DfQty",
  ];
  const columnsStd = [
    "Date",
    "Shift",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Last5DaysPerfPercent",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
    "ScannedGrillsQty",
    "Setup",
  ];
  const columns = [
    "Date",
    "Shift",
    "Points",
    "Loading",
    "PerfDaily",
    "Last6WeeksPerformance",
    "Last5DaysPerfPercent",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
  ];
  const columnsOem = [
    "Date",
    "Shift",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Last5DaysPerfPercent",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
  ];
  const columnsLIN = [
    "Date",
    "Shift",
    "Points",
    "Loading",
    "PerfDaily",
    "Last6WeeksPerformance",
    "PerfWeekly",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
    "Points",
    "Loading",
    "PerfDaily",
    "PerfWeekly",
    "Hours per shift",
    "HSW",
    "Last5DaysPerfPercent",
  ];


  const columnsTh2 = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Morning",
    "",
    "",
    "",
    "",
    "",
    "Evening",
    "",
    "",
    "",
    "",
    "",
    "",
  ];
  const columnsTh1 = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Morning",
    "",
    "",
    "",
    "",
    "",
    "Evening",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ];
  const columnsTh3 = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Morning",
    "",
    "",
    "",
    "",
    "",
    "Evening",
    "",
    "",
    "",
    "",
    "",
  ];
  const columnsTh4 = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Morning",
    "",
    "",
    "",
    "",
    "",
    "Evening",
    "",
    "",
    "",
    "",
    "",
    "",
  ];
  const columnsHSW = [
    "Date",
    "LAD",
    "DF",
    "STD",
    "N7",
    "PANEL",
    "LIN",
    "CUSTOM",
    "PAINTLINE",
    "LOUVERS",
    "STAMPING",
    "TECHINCAL STAFF",
    "MANAGEMENT"
  ];

  export {columns,columnsStd,columnsDf,columnsOem,columnsLIN,small,smallLIN,columnsTh3,columnsTh2,columnsTh1,columnsTh4,columnsHSW}