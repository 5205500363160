import React from "react";
// import AccountingIcon from '@mui/icons-material/AccountBalance';
// import Production from '@mui/icons-material/Inventory';
// import SalesIcon from '@mui/icons-material/MonetizationOn';
// import MachineStatusIcon from '@mui/icons-material/PrecisionManufacturing';
import Routes from "./Routes";
import GovernanceIcon from "../../assets/images/Governance.svg";
import ProductionIcon from "../../assets/images/Production.svg";
import GraphsIcon from "../../assets/images/Graph.svg";
import ProductionDelayIcon from "../../assets/images/Delay.svg";
import {ReactComponent as PayrollData} from "../../assets/images/PayrollData.svg";
import CalendarIcon from "@mui/icons-material/CalendarMonth";

//import GovernanceIcon from '@mui/icons-material/SettingsApplications';

export const SidebarData = [
  {
    title: "Governance",
    icon: <img src={GovernanceIcon} alt="Governance" />,
    link: Routes.Governence,
    id: "active",
    users: ["Admin", "AirvectorCustomer", "Manager", "Mechanic"],
    subSections: [
      {
        title: "Graphs",
        icon: <img src={GraphsIcon} alt="Graphs" />,
        link: Routes.Chart,
        id: "",
        users: ["Admin", "AirvectorCustomer", "Manager"],
      },
    ],
  },
  {
    title: "Payroll Data", // renamed to "Payroll Data"
    icon: <PayrollData fill="#737373"/>, 
    link: "/payroll",
    id: "",
    users: ["Admin", "AirvectorCustomer", "Manager"],
  },
  {
    title: "Production",
    icon: (
      <img
        src={ProductionIcon}
        alt="Production"
        style={{ transform: "scale(0.9)" }}
      />
    ),
    link: Routes.Production,
    id: "",
    users: ["Admin", "AirvectorCustomer", "Manager", "Production"],
    subSections: [
      {
        title: "Product Delay",
        icon: <img src={ProductionDelayIcon} alt="Production Delay" />,
        link: "/production/product-delay",
        id: "",
        users: ["Admin", "AirvectorCustomer", "Manager"],
      },
      {
        title: "HSW",
        icon: <img src={ProductionDelayIcon} alt="Production Delay" />,
        link: "/production/hsw",
        id: "",
        users: ["Admin", "AirvectorCustomer", "Manager"],
      },
    ],
  },


  /*Should it be hidden?*/
  /* 
    {
        title: "Sales",
        icon: <SalesIcon />,
        link: "/sales",
        id: "hiddenTab"
    },
    {
        title: "Machine status",
        icon: <MachineStatusIcon />,
        link: "/machineStatus",
        id: "hiddenTab"
    },
    {
        title: "Accountings",
        icon: <AccountingIcon />,
        link: "/accountings",
        id: "hiddenTab"
    }, */
];
