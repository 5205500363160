import React, { useState } from "react";
import Sidebar from "../Components/SideBar";
import { useAuth } from "react-oidc-context";
import HSWWindow from "../Components/Windows/hswWindow";


function wrapContent(auth, content) {
  switch (auth.activeNavigator) {
    case "signinSilent":
      return <div>Signing you in...</div>;
    case "signoutRedirect":
      return <div>Signing you out...</div>;
  }

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return <React.Fragment>{content()}</React.Fragment>;
  }
  auth.signinRedirect();
  //return <button onClick={() => void auth.signinRedirect()}>Log in</button>;
}

function content() {
  //const value = useContext(salesGovernanceContext); //to remove
  return (
    <React.Fragment>
      <div className="staticElement">
        <Sidebar />
      </div>
      <div className="hswWindow">
        
          <HSWWindow />
      </div>
    </React.Fragment>
  );
}

function HSW() {
  const auth = useAuth();

  return <div className="parentDiv">{wrapContent(auth, content)}</div>;
}

export default HSW;
