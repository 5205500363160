import React, { useState } from "react";
import axios from "axios";
import { SGD_API_HOST } from "../../../env";

function HSWInputMarker(conf) {
  const [tempValue, setTempValue] = useState(conf.value); // For immediate user input

  const handleClick = (event, id) => {
    conf.setInputId((prevState) => ({
      clicked: prevState.clicked === id ? null : id, // Toggle input state
    }));
  };

  const handleBlur = (e, perf, depId, dateOfEntry) => {
    const newValue = e.currentTarget.value;
    setTempValue(newValue); // Update immediately in the UI

    // Construct the API URL
    const url =
      `${SGD_API_HOST}/api/HealthAndSafety/update-hsw-by-id` +
      `?id=${perf}&points=${newValue}&departmentId=${depId}&dateOfEntry=${dateOfEntry}&shift=ms-`;

    // Perform the DB update in the background
    axios.put(url).catch((error) => {
      console.error("Failed to update the database:", error);
    });

    // Close the input
    conf.setInputId({ clicked: -1 });
  };

  return (
    <td
      key={conf.index}
      style={conf.style}
      onDoubleClick={(event) => handleClick(event, conf.index)}
    >
      {conf.clicked === conf.index ? (
        <input
          className="table-input"
          type="text"
          defaultValue={tempValue} // Show the current temporary value
          onBlur={(event) =>
            handleBlur(
              event,
              conf.perfId,
              conf.departmentId,
              conf.dateOfEntry
            )
          }
          autoFocus
        />
      ) : (
        <span className="input-like">{tempValue}</span> // Always show the latest value
      )}
    </td>
  );
}

export default HSWInputMarker;
